import React from "react"
import { graphql } from "gatsby"
import statWinner from "../../js/statWinner"
import statPer90 from "../../js/statPer90"

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import DetailedStatBars from "../../components/DetailedStats/DetailedStatBars"
import LastUpdated from "../../components/LastUpdated/LastUpdated"
import CopyBlock from "../../components/Copy/CopyBlock"
import InfoBar from '../../components/Utilities/InfoBar'


class KeyPasses extends React.Component {

  state = {
    p90: false,
  };

  manageP90Toggle = () => {
    this.setState(state => ({ p90: !state.p90 }));
  };

  render() {

    const pageTitle = "Key Passes"

    const mdata = this.props.data.allSheetMessiAllTimeStats.edges
    const rdata = this.props.data.allSheetRonaldoAllTimeStats.edges

    const subStat = (x, comp) => {
      return x === "m" ? (
        parseFloat(mdata.filter(d => d.node.competition === comp).map(d => d.node.keyPasses)[0] / (mdata.filter(d => d.node.competition === comp).map(d => d.node.minsPlayed)[0] / 90)).toFixed(2)
      ) : (
        parseFloat(rdata.filter(d => d.node.competition === comp).map(d => d.node.keyPasses)[0] / (rdata.filter(d => d.node.competition === comp).map(d => d.node.minsPlayed)[0] / 90)).toFixed(2)
      )
    }

    const comps = [
      {
        "comp": "League + Champs League",
        "mSubStat": subStat('m', 'League and Europe Since 09'),
        "rSubStat": subStat('r', 'League and Europe Since 09'),
        "btnText": "Season Breakdown",
      },
      {
        "comp": "League Since 09",
        "mSubStat": subStat('m', 'League Since 09'),
        "rSubStat": subStat('r', 'League Since 09'),
        "btnText": "Season Breakdown",
        "title": "League",
      },
      {
        "comp": "Europe Since 09",
        "mSubStat": subStat('m', 'Europe Since 09'),
        "rSubStat": subStat('r', 'Europe Since 09'),
        "btnText": "Season Breakdown",
        "title": "Champions League",
      },
    ]


    // Below stuff is for the key passes breakdown tables
    let mdatax = this.props.data.allSheetMessiAdditionalSeasonStats.edges
    let rdatax = this.props.data.allSheetRonaldoAdditionalSeasonStats.edges

    // This line is required due to node support issues for Object.fromEntries
    Object.fe = arr => Object.assign({}, ...Array.from(arr, ([k, v]) => ({[k]: v}) ));

    // Map over the two arrays and prefix all the keys with m or r
    mdatax = mdatax.map(entry => { return Object.fe( Object.entries(entry).map(([key, value]) => [`m${key}`, value] ) ) })
    rdatax = rdatax.map(entry => { return Object.fe( Object.entries(entry).map(([key, value]) => [`r${key}`, value] ) ) })

    // Combine the two arrays so we have both players' stats in a single object per year/season
    const bothData = mdatax.map((item, key) => Object.assign({}, item, rdatax[key]))


    const shortSeason = (season) => {
      return season.replace(/-/g, '/').replace(/20/g,'').replace('/21','20/21').replace('19/','19/20')
    }

    const shortComp = (comp) => {
      return comp.replace('Champions League','UCL').replace('All Competitions','Total')
    }

    const comp = (data,p,comp = "All Competitions") => {
      return p == "m" ? data.filter(d => d.mnode.competition === comp) : data.filter(d => d.rnode.competition === comp)
    }

    return (

      <Layout>
        <SEO
          title={`${pageTitle} - Messi vs Ronaldo All Time Key Pass Stats`}
          description={`Their stunning scoring rates have seen Messi & Ronaldo's impressive passing stats go under the radar at times, but who boasts the best numbers when it comes to key passes?`}
          canonicalPath={`/detailed-stats/key-passes/`}
        />

        <h1>{pageTitle} <span className="sr-only"> - Messi vs Ronaldo All Time Key Pass Stats</span></h1>

        <div className="flex items-center justify-center mt-8 lg:mt-10 xl:mt-12 max-w-xs mb-2 xl:mb-4 mx-auto px-4">
          <InfoBar classes="w-full justify-center" msg="Non-European league stats not included in totals" />
        </div>

        <div className={`max-w-lg mx-auto flex flex-wrap justify-center`}>

          {comps.map(d => (
            <DetailedStatBars
              key={d.comp}
              data={this.props.data}
              competition={d.comp}
              playerTitleHidden={`${d.comp} ${pageTitle} since 09/10`}
              statType={pageTitle}
              btnText={d.btnText}
              statView={['Totals', "Per 90 mins"]}
              mSubStat={`${d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-'} key passes every 90 minutes`}
              rSubStat={`${d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-'} key passes every 90 minutes`}
              subStatW={statWinner(d.mSubStat.toString() !== "NaN" ? d.mSubStat : '-', d.rSubStat.toString() !== "NaN" ? d.rSubStat : '-')}
              since="09/10"
              title={d.title}
            />
          ))}

        </div>

        <CopyBlock>

          <h2>Messi and Ronaldo Key Passes Breakdown</h2>

          <InfoBar msg="Unlike the stats above, the detailed stats below do not include stats from the current season." />

          <p>First of all, what is a key pass? A key pass is any pass that leads to the recipient of that pass having a shot. To put it simply, a more accurate name for this stat would be "shot assist".</p>

          <h2>Key Passes by Length</h2>

          <div className="" onChange={this.manageP90Toggle}>
            <label className={`flex w-full items-center p-2 cursor-pointer`}>
              <input type="checkbox" checked={this.state.p90 ? true : false} />
              <span className="ml-2">Per 90 Stats</span>
            </label>
          </div>

          <div className="w-full overflow-x-scroll scrolling-touch hide-scrollbars text-sm">
            <table className="w-full">
              <thead className="text-left">
              <tr>
                <th className="border-gray-700"></th>
                <th className="border-gray-700" colSpan="2">Total</th>
                <th className="border-gray-700" colSpan="2">Short</th>
                <th colSpan="2">Long</th>
              </tr>
              <tr>
                <th className="border-gray-700 text-left">Season / Comp</th>
                <th className="bg-messi-darken5">M<span class="hidden md:inline">essi</span> <span className="sr-only">Messi total key passes</span></th>
                <th className="bg-ronaldo-darken5 border-gray-700">R<span class="hidden md:inline">onaldo</span> <span className="sr-only">Ronaldo total key passes</span></th>
                <th className="bg-messi-darken5">M<span class="hidden md:inline">essi</span> <span className="sr-only">Messi short key passes</span></th>
                <th className="bg-ronaldo-darken5 border-gray-700">R<span class="hidden md:inline">onaldo</span> <span className="sr-only">Ronaldo short key passes</span></th>
                <th className="bg-messi-darken5">M<span class="hidden md:inline">essi</span> <span className="sr-only">Messi long key passes</span></th>
                <th className="bg-ronaldo-darken5">R<span class="hidden md:inline">onaldo</span> <span className="sr-only">Ronaldo long key passes</span></th>
              </tr>
              </thead>
              <tbody>
              {bothData.map(
                d => (
                  <tr key={d.rnode.year+d.rnode.competition} className={`${d.rnode.competition === 'All Competitions' ? 'border-b-2 border-gray-700 font-semibold bg-gray-800' : ''}`}>
                    <td className="text-left whitespace-no-wrap border-gray-700">{shortSeason(d.rnode.year)} {shortComp(d.rnode.competition)}</td>
                    {this.state.p90 ? (
                      <>
                        <td className={`${statPer90(d.mnode.keyPasses,d.mnode.minsPlayed) > statPer90(d.rnode.keyPasses,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.keyPasses,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.keyPasses,d.rnode.minsPlayed) > statPer90(d.mnode.keyPasses,d.mnode.minsPlayed) ? 'text-highlight' : ''} border-gray-700`}>
                          {statPer90(d.rnode.keyPasses,d.rnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.mnode.shortKps,d.mnode.minsPlayed) > statPer90(d.rnode.shortKps,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.shortKps,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.shortKps,d.rnode.minsPlayed) > statPer90(d.mnode.shortKps,d.mnode.minsPlayed) ? 'text-highlight' : ''} border-gray-700`}>
                          {statPer90(d.rnode.shortKps,d.rnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.mnode.longKps,d.mnode.minsPlayed) > statPer90(d.rnode.longKps,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.longKps,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.longKps,d.rnode.minsPlayed) > statPer90(d.mnode.longKps,d.mnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.rnode.longKps,d.rnode.minsPlayed)}
                        </td>
                      </>
                    ) : (
                      <>
                        <td className={`${Number(d.mnode.keyPasses) > Number(d.rnode.keyPasses) ? 'text-highlight' : ''}`}>
                          {d.mnode.keyPasses}
                        </td>
                        <td className={`${Number(d.rnode.keyPasses) > Number(d.mnode.keyPasses) ? 'text-highlight' : ''} border-gray-700`}>
                          {d.rnode.keyPasses}
                        </td>
                        <td className={`${Number(d.mnode.shortKps) > Number(d.rnode.shortKps) ? 'text-highlight' : ''}`}>
                          {d.mnode.shortKps}
                        </td>
                        <td className={`${Number(d.rnode.shortKps) > Number(d.mnode.shortKps) ? 'text-highlight' : ''} border-gray-700`}>
                          {d.rnode.shortKps}
                        </td>
                        <td className={`${Number(d.mnode.longKps) > Number(d.rnode.longKps) ? 'text-highlight' : ''}`}>
                          {d.mnode.longKps}
                        </td>
                        <td className={`${Number(d.rnode.longKps) > Number(d.mnode.longKps) ? 'text-highlight' : ''}`}>
                          {d.rnode.longKps}
                        </td>
                      </>
                    )}
                  </tr>
                )
              )}
              <tr>
                <th className="text-highlight">Totals</th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.keyPasses)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.keyPasses)) }, 0)}
                </th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.shortKps)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.shortKps)) }, 0)}
                </th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.longKps)) }, 0)}
                </th>
                <th className="text-ronaldo">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.longKps)) }, 0)}
                </th>
              </tr>
              </tbody>
            </table>
          </div>

          <p>The data shows us that Leo Messi makes cosiderably more short key passes and long key passes than Cristiano Ronaldo, although the numbers were a lot more even during Ronaldo's early Real Madrid years.</p>

          <p>Although we don't have the data before 2009/10, we can theorise that Ronaldo's key pass numbers at Manchester United would have been fairly similar to his early Madrid career (if not greater) due to having more involvement in build-up play.</p>


          <h2>Key Passes by Type</h2>
          <p className="text-highlight">NOTE: a key pass can be multiple types (e.g. one key pass can be both a free kick and a cross)</p>

          <div className="" onChange={this.manageP90Toggle}>
            <label className={`flex w-full items-center p-2 cursor-pointer`}>
              <input type="checkbox" checked={this.state.p90 ? true : false} />
              <span className="ml-2">Per 90 Stats</span>
            </label>
          </div>

          <div className="w-full overflow-x-scroll scrolling-touch hide-scrollbars text-sm">
            <table className="w-full">
              <thead className="text-left">
              <tr>
                <th className="border-gray-700"></th>
                <th className="border-gray-700" colSpan="2">Corner</th>
                <th className="border-gray-700" colSpan="2">Free Kick</th>
                <th className="border-gray-700" colSpan="2">Throw-in</th>
                <th className="border-gray-700" colSpan="2">Throughball</th>
                <th className="border-gray-700" colSpan="2">Cross</th>
                <th colSpan="2">Other</th>
              </tr>
              <tr>
                <th className="border-gray-700 text-left">Season / Comp</th>
                <th className="bg-messi-darken5">M <span className="sr-only">Messi corner key passes</span></th>
                <th className="bg-ronaldo-darken5 border-gray-700">R <span className="sr-only">Ronaldo corner key passes</span></th>
                <th className="bg-messi-darken5">M <span className="sr-only">Messi free kick key passes</span></th>
                <th className="bg-ronaldo-darken5 border-gray-700">R <span className="sr-only">Ronaldo free kick key passes</span></th>
                <th className="bg-messi-darken5">M <span className="sr-only">Messi throw-in key passes</span></th>
                <th className="bg-ronaldo-darken5">R <span className="sr-only">Ronaldo throw-in key passes</span></th>
                <th className="bg-messi-darken5">M <span className="sr-only">Messi throughball key passes</span></th>
                <th className="bg-ronaldo-darken5">R <span className="sr-only">Ronaldo throughball key passes</span></th>
                <th className="bg-messi-darken5">M <span className="sr-only">Messi cross key passes</span></th>
                <th className="bg-ronaldo-darken5">R <span className="sr-only">Ronaldo cross key passes</span></th>
                <th className="bg-messi-darken5">M <span className="sr-only">Messi other key passes</span></th>
                <th className="bg-ronaldo-darken5">R <span className="sr-only">Ronaldo other key passes</span></th>
              </tr>
              </thead>
              <tbody>
              {bothData.map(
                d => (
                  <tr key={d.rnode.year+d.rnode.competition} className={`${d.rnode.competition === 'All Competitions' ? 'border-b-2 border-gray-700 font-semibold bg-gray-800' : ''}`}>
                    <td className="text-left whitespace-no-wrap border-gray-700">{shortSeason(d.rnode.year)} {shortComp(d.rnode.competition)}</td>
                    {this.state.p90 ? (
                      <>
                        <td className={`${statPer90(d.mnode.cornerKps,d.mnode.minsPlayed) > statPer90(d.rnode.cornerKps,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.cornerKps,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.cornerKps,d.rnode.minsPlayed) > statPer90(d.mnode.cornerKps,d.mnode.minsPlayed) ? 'text-highlight' : ''} border-gray-700`}>
                          {statPer90(d.rnode.cornerKps,d.rnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.mnode.freeKickKps,d.mnode.minsPlayed) > statPer90(d.rnode.freeKickKps,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.freeKickKps,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.freeKickKps,d.rnode.minsPlayed) > statPer90(d.mnode.freeKickKps,d.mnode.minsPlayed) ? 'text-highlight' : ''} border-gray-700`}>
                          {statPer90(d.rnode.freeKickKps,d.rnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.mnode.throwinKps,d.mnode.minsPlayed) > statPer90(d.rnode.throwinKps,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.throwinKps,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.throwinKps,d.rnode.minsPlayed) > statPer90(d.mnode.throwinKps,d.mnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.rnode.throwinKps,d.rnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.mnode.throughballKps,d.mnode.minsPlayed) > statPer90(d.rnode.throughballKps,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.throughballKps,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.throughballKps,d.rnode.minsPlayed) > statPer90(d.mnode.throughballKps,d.mnode.minsPlayed) ? 'text-highlight' : ''} border-gray-700`}>
                          {statPer90(d.rnode.throughballKps,d.rnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.mnode.crossKps,d.mnode.minsPlayed) > statPer90(d.rnode.crossKps,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.crossKps,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.crossKps,d.rnode.minsPlayed) > statPer90(d.mnode.crossKps,d.mnode.minsPlayed) ? 'text-highlight' : ''} border-gray-700`}>
                          {statPer90(d.rnode.crossKps,d.rnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.mnode.otherKps,d.mnode.minsPlayed) > statPer90(d.rnode.otherKps,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.otherKps,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.otherKps,d.rnode.minsPlayed) > statPer90(d.mnode.otherKps,d.mnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.rnode.otherKps,d.rnode.minsPlayed)}
                        </td>
                      </>
                    ) : (
                      <>
                        <td className={`${Number(d.mnode.cornerKps) > Number(d.rnode.cornerKps) ? 'text-highlight' : ''}`}>
                          {d.mnode.cornerKps}
                        </td>
                        <td className={`${Number(d.rnode.cornerKps) > Number(d.mnode.cornerKps) ? 'text-highlight' : ''} border-gray-700`}>
                          {d.rnode.cornerKps}
                        </td>
                        <td className={`${Number(d.mnode.freeKickKps) > Number(d.rnode.freeKickKps) ? 'text-highlight' : ''}`}>
                          {d.mnode.freeKickKps}
                        </td>
                        <td className={`${Number(d.rnode.freeKickKps) > Number(d.mnode.freeKickKps) ? 'text-highlight' : ''} border-gray-700`}>
                          {d.rnode.freeKickKps}
                        </td>
                        <td className={`${Number(d.mnode.throwinKps) > Number(d.rnode.throwinKps) ? 'text-highlight' : ''}`}>
                          {d.mnode.throwinKps}
                        </td>
                        <td className={`${Number(d.rnode.throwinKps) > Number(d.mnode.throwinKps) ? 'text-highlight' : ''} border-gray-700`}>
                          {d.rnode.throwinKps}
                        </td>
                        <td className={`${Number(d.mnode.throughballKps) > Number(d.rnode.throughballKps) ? 'text-highlight' : ''}`}>
                          {d.mnode.throughballKps}
                        </td>
                        <td className={`${Number(d.rnode.throughballKps) > Number(d.mnode.throughballKps) ? 'text-highlight' : ''} border-gray-700`}>
                          {d.rnode.throughballKps}
                        </td>
                        <td className={`${Number(d.mnode.crossKps) > Number(d.rnode.crossKps) ? 'text-highlight' : ''}`}>
                          {d.mnode.crossKps}
                        </td>
                        <td className={`${Number(d.rnode.crossKps) > Number(d.mnode.crossKps) ? 'text-highlight' : ''} border-gray-700`}>
                          {d.rnode.crossKps}
                        </td>
                        <td className={`${Number(d.mnode.otherKps) > Number(d.rnode.otherKps) ? 'text-highlight' : ''}`}>
                          {d.mnode.otherKps}
                        </td>
                        <td className={`${Number(d.rnode.otherKps) > Number(d.mnode.otherKps) ? 'text-highlight' : ''}`}>
                          {d.rnode.otherKps}
                        </td>
                      </>
                    )}
                  </tr>
                )
              )}
              <tr>
                <th className="text-highlight">Totals</th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.cornerKps)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.cornerKps)) }, 0)}
                </th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.freeKickKps)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.freeKickKps)) }, 0)}
                </th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.throwinKps)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.throwinKps)) }, 0)}
                </th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.throughballKps)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.throughballKps)) }, 0)}
                </th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.crossKps)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.crossKps)) }, 0)}
                </th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.otherKps)) }, 0)}
                </th>
                <th className="text-ronaldo">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.otherKps)) }, 0)}
                </th>
              </tr>
              </tbody>
            </table>
          </div>

          <p>We can see that Messi makes a lot of key passes from dead-ball situations like corners and free kicks, whereas Ronaldo doesn't take corners and almost never attempts to assist from free kicks.</p>

          <p>Messi also dominates the other key pass types, making around 200 more key passes from throughballs, 35 more key passes from crosses, and over 100 more key passes from "other" situations.</p>

          <h2>Set Piece / Open Play Key Passes</h2>

          <div className="" onChange={this.manageP90Toggle}>
            <label className={`flex w-full items-center p-2 cursor-pointer`}>
              <input type="checkbox" checked={this.state.p90 ? true : false} />
              <span className="ml-2">Per 90 Stats</span>
            </label>
          </div>

          <div className="w-full overflow-x-scroll scrolling-touch hide-scrollbars text-sm">
            <table className="w-full">
              <thead className="text-left">
              <tr>
                <th className="border-gray-700"></th>
                <th className="border-gray-700" colSpan="2">Total</th>
                <th className="border-gray-700" colSpan="2">Set Piece</th>
                <th colSpan="2">Open Play</th>
              </tr>
              <tr>
                <th className="border-gray-700 text-left">Season / Comp</th>
                <th className="bg-messi-darken5">M<span class="hidden md:inline">essi</span> <span className="sr-only">Messi total key passes</span></th>
                <th className="bg-ronaldo-darken5 border-gray-700">R<span class="hidden md:inline">onaldo</span> <span className="sr-only">Ronaldo total key passes</span></th>
                <th className="bg-messi-darken5">M<span class="hidden md:inline">essi</span> <span className="sr-only">Messi set-piece key passes</span></th>
                <th className="bg-ronaldo-darken5 border-gray-700">R<span class="hidden md:inline">onaldo</span> <span className="sr-only">Ronaldo set-piece key passes</span></th>
                <th className="bg-messi-darken5">M<span class="hidden md:inline">essi</span> <span className="sr-only">Messi open play key passes</span></th>
                <th className="bg-ronaldo-darken5">R<span class="hidden md:inline">onaldo</span> <span className="sr-only">Ronaldo open play key passes</span></th>
              </tr>
              </thead>
              <tbody>
              {bothData.map(
                d => (
                  <tr key={d.rnode.year+d.rnode.competition} className={`${d.rnode.competition === 'All Competitions' ? 'border-b-2 border-gray-700 font-semibold bg-gray-800' : ''}`}>
                    <td className="text-left whitespace-no-wrap border-gray-700">{shortSeason(d.rnode.year)} {shortComp(d.rnode.competition)}</td>
                    {this.state.p90 ? (
                      <>
                        <td className={`${statPer90(d.mnode.keyPasses,d.mnode.minsPlayed) > statPer90(d.rnode.keyPasses,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.keyPasses,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.keyPasses,d.rnode.minsPlayed) > statPer90(d.mnode.keyPasses,d.mnode.minsPlayed) ? 'text-highlight' : ''} border-gray-700`}>
                          {statPer90(d.rnode.keyPasses,d.rnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.mnode.setPieceKps,d.mnode.minsPlayed) > statPer90(d.rnode.setPieceKps,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.setPieceKps,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.setPieceKps,d.rnode.minsPlayed) > statPer90(d.mnode.setPieceKps,d.mnode.minsPlayed) ? 'text-highlight' : ''} border-gray-700`}>
                          {statPer90(d.rnode.setPieceKps,d.rnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.mnode.openPlayKps,d.mnode.minsPlayed) > statPer90(d.rnode.openPlayKps,d.rnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.mnode.openPlayKps,d.mnode.minsPlayed)}
                        </td>
                        <td className={`${statPer90(d.rnode.openPlayKps,d.rnode.minsPlayed) > statPer90(d.mnode.openPlayKps,d.mnode.minsPlayed) ? 'text-highlight' : ''}`}>
                          {statPer90(d.rnode.openPlayKps,d.rnode.minsPlayed)}
                        </td>
                      </>
                    ) : (
                      <>
                        <td className={`${Number(d.mnode.keyPasses) > Number(d.rnode.keyPasses) ? 'text-highlight' : ''}`}>
                          {d.mnode.keyPasses}
                        </td>
                        <td className={`${Number(d.rnode.keyPasses) > Number(d.mnode.keyPasses) ? 'text-highlight' : ''} border-gray-700`}>
                          {d.rnode.keyPasses}
                        </td>
                        <td className={`${Number(d.mnode.setPieceKps) > Number(d.rnode.setPieceKps) ? 'text-highlight' : ''}`}>
                          {d.mnode.setPieceKps}
                        </td>
                        <td className={`${Number(d.rnode.setPieceKps) > Number(d.mnode.setPieceKps) ? 'text-highlight' : ''} border-gray-700`}>
                          {d.rnode.setPieceKps}
                        </td>
                        <td className={`${Number(d.mnode.openPlayKps) > Number(d.rnode.openPlayKps) ? 'text-highlight' : ''}`}>
                          {d.mnode.openPlayKps}
                        </td>
                        <td className={`${Number(d.rnode.openPlayKps) > Number(d.mnode.openPlayKps) ? 'text-highlight' : ''}`}>
                          {d.rnode.openPlayKps}
                        </td>
                      </>
                    )}
                  </tr>
                )
              )}
              <tr>
                <th className="text-highlight">Totals</th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.keyPasses)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.keyPasses)) }, 0)}
                </th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.setPieceKps)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.setPieceKps)) }, 0)}
                </th>
                <th className={`text-messi`}>
                  {comp(bothData,'m').reduce((total, d) => { return (total + Number(d.mnode.openPlayKps)) }, 0)}
                </th>
                <th className="text-ronaldo border-gray-700">
                  {comp(bothData,'r').reduce((total, d) => { return (total + Number(d.rnode.openPlayKps)) }, 0)}
                </th>
              </tr>
              </tbody>
            </table>
          </div>

          <p>As mentioned above, many of Messi's key passes come from set-piece situations, as he takes most of the corners and free kicks for his team. He has made over 100 more key passes from corners, free kicks or throw-ins than Cristiano.</p>

          <p>Messi has also made over 200 more key passes from open play, but the numbers were a lot more even during Ronaldo's early seasons at Real Madrid.</p>

          <p>All key pass stats are via Opta-powered <a className="underline" href="https://www.whoscored.com">WhoScored</a>.</p>

        </CopyBlock>

        <LastUpdated type="Stats" />
        
      </Layout>

    )
  }
}

export const query = graphql`
  query {
    allSheetMessiAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          competition
          apps
          minsPlayed
          keyPasses
        }
      }
    }
    allSheetRonaldoAllTimeStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          competition
          apps
          minsPlayed
          keyPasses
        }
      }
    }
    allSheetMessiClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          keyPasses
        }
      }
    }
    allSheetRonaldoClubStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          keyPasses
        }
      }
    }
    allSheetMessiIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          keyPasses
        }
      }
    }
    allSheetRonaldoIntStats(filter: {pgKey: {regex: "/detailed/"}}) {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          keyPasses
        }
      }
    }
    allSheetMessiAdditionalSeasonStats {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          keyPasses
          longKps
          shortKps
          cornerKps
          freeKickKps
          throughballKps
          crossKps
          throwinKps
          otherKps
          setPieceKps
          openPlayKps
        }
      }
    }
    allSheetRonaldoAdditionalSeasonStats {
      edges {
        node {
          year
          competition
          apps
          minsPlayed
          keyPasses
          longKps
          shortKps
          cornerKps
          freeKickKps
          throughballKps
          crossKps
          throwinKps
          otherKps
          setPieceKps
          openPlayKps
        }
      }
    }
  }
`

export default KeyPasses
